<template>
    <div class="columns is-vcentered box mb-5 ml-2 mr-2">
        <div class="column is-2 has-text-left is-full-mobile">
            <p class="has-text-weight-semibold"> {{this.getHoursAndMinutes(event.event_utc_ts)}} </p>
            <p class="subtitle	has-text-grey is-size-6">{{this.getMonthAndDay(event.event_utc_ts)}}</p>
        </div>
        <div class="column is-four-fifths is-full-mobile">
            <div class="columns is-mobile is-vcentered is-centered is-multiline">
                <div class="column is-3-desktop is-full-mobile">
                    <p class="has-text-weight-semibold is-size-5 pl-2 has-text-left"> {{this.trimParticipant(event.participants[0].name)}} </p>
                </div>
                <div class="column is-2-desktop is-3-mobile has-text-right">
                    <figure class="image is-96x96">
                        <image-component :path="event.participants[0].images" alt="banner"/>
                    </figure>
                </div>
                <div class="column is-1-desktop center-text is-2-mobile">
                    <p class="subtitle is-size-6 has-text-grey mb-2"> VS </p>
                    <p class="has-text-warning caption is-size-7 has-text-weight-semibold" v-if="event.caption"> {{event.caption}} </p>
                </div>
                <div class="column is-2-desktop is-3-mobile pl-0">
                    <figure class="image is-96x96">
                        <image-component  :path="event.participants[1].images" alt="banner"/>
                    </figure>
                </div>
                <div class="column is-3-desktop is-full-mobile has-text-right">
                    <p class="has-text-weight-semibold is-size-5 pl-2"> {{this.trimParticipant(event.participants[1].name)}} </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ImageComponent from "../base-components/ImageComponent";
    export default {
        name: "UpcomingGamesComponent",
      components: {ImageComponent},
      props: {
            event: Object
        },
        computed: {
            randomTime() {
                return Math.floor(Math.random() * (12)) + 1;
            },
        },
        methods: {
            getTime(timestamp) {
                timestamp = timestamp * 1000;
                let formatter = new Intl.DateTimeFormat('EN', {
                    hour: "numeric",
                    minute: "numeric",
                    timeZoneName: "short",
                });
                return formatter.format(timestamp);
            },
        }
    }
</script>

<style lang="scss" scoped>
    @import "src/assets/css/mixins";
    .caption {
        min-width: 100px;
    }
    .center-text{
        @include media-max(sm) {
            padding-right: 0;
        }
    }
</style>
