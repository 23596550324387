<template>
    <div v-if="participants">
        <div class="columns is-centered is-multiline is-mobile">
            <div v-for="item in participants.slice(0,6)" :key="item.name" class="column is-2-desktop is-2-tablet is-one-third-mobile is-flex is-align-items-center is-flex-direction-column is-centered">
                <figure class="image is-128x128">
                    <image-component :path="item.images" :alt="item.name"/>
                </figure>
                <p class="has-text-weight-semibold">{{trimParticipant(item.name)}}</p>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from "vuex";
    import {API} from "../../const";
    import ImageComponent from "../base-components/ImageComponent";

    export default {
      name: "ParticipantsComponent",
      components: {ImageComponent},
      props: {
            events: Object
        },
        data() {
            return {
                baseImgLink: API.MEDIA_HOST
            }
        },
        computed: {
            ...mapState({
                participants: state => state.participant.participantsList,
            }),
        }
    }
</script>

<style scoped>

</style>