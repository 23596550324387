<template>
    <div class="container is-widescreen">
        <slider-component :slides="slides" v-if="slides"></slider-component>
        <div class="columns is-centered mb-5" v-if="upcomingEvents.length">
            <div class="column is-four-fifths ">
                <div class="level level-is-shrinkable">
                    <div class="level-item level-left has-text-centered pl-5">
                        <h3 class="title is-4 mb-4">
                            Upcoming games
                        </h3>
                    </div>
                    <div class="level-item level-right has-text-centered pr-5">
                        <a href="/" class="has-text-weight-semibold">
                            VIEW ALL
                        </a>
                    </div>
                </div>
                <upcoming-games-component v-for="(item, index) in upcomingEvents"
                                          :key="index"
                                          :event="item">

                </upcoming-games-component>
            </div>
        </div>
        <div class="columns is-centered" v-if="pastEvents.length">
            <div class="column is-four-fifths ">
                <div class="level level-is-shrinkable">
                    <div class="level-item level-left has-text-centered pl-5">
                        <h3 class="title is-4 mb-4">
                            Past games
                        </h3>
                    </div>
                    <div class="level-item level-right has-text-centered pr-5">
                        <a href="/" class="has-text-weight-semibold">
                            VIEW ALL
                        </a>
                    </div>
                </div>
                <past-games-component :events="pastEvents">

                </past-games-component>
            </div>
        </div>
        <div class="columns is-centered" v-if="participants">
            <div class="column is-four-fifths ">
                <div class="level level-is-shrinkable">
                    <div class="level-item level-left has-text-centered pl-5">
                        <h3 class="title is-4 mb-4">
                            Schools
                        </h3>
                    </div>
                    <div class="level-item level-right has-text-centered pr-5">
                        <a href="/" class="has-text-weight-semibold">
                            VIEW ALL
                        </a>
                    </div>
                </div>
                <participants-component>

                </participants-component>
            </div>
        </div>
    </div>
</template>

<script>
  import {mapCacheActions} from "vuex-cache";
  import {mapState} from "vuex";
  import UpcomingGamesComponent from "../../components/main/UpcomingGamesComponent";
  import PastGamesComponent from "../../components/main/PastGamesComponent";
  import SliderComponent from "../components/main/SliderComponent";
  import ParticipantsComponent from "../../components/main/ParticipantsComponent";

  export default {
    name: "HomeScreen",
    components: {SliderComponent, ParticipantsComponent, PastGamesComponent, UpcomingGamesComponent},
    data() {
      return {
        requestUpcomingEventsParams: {
          start_date: Math.floor(Date.now() / 1000),
          limit: 3,
        },
        requestPastEventsParams: {
          start_date: 1,
          limit: 6,
        },
        upcomingEvents: [],
        pastEvents: [],
      }
    },
    methods: {
      ...mapCacheActions(['GET_EVENTS_LIST', 'GET_PARTICIPANTS_LIST', 'GET_CHANNEL_SLIDES']),
      makeRequest() {
        this.GET_EVENTS_LIST(this.requestUpcomingEventsParams)
            .then((response) => {
              response.data.forEach((value) => {
                this.upcomingEvents.push(value)
              });
            });
        this.GET_EVENTS_LIST(this.requestPastEventsParams)
            .then((response) => {
              response.data.forEach((value) => {
                this.pastEvents.push(value)
              });
            });
        this.GET_PARTICIPANTS_LIST();
        this.GET_CHANNEL_SLIDES();
      },
    },
    computed: {
      ...mapState({
        participants: state => state.participant.participantsList,
        slides: state => state.channel.slides,
      }),
    },
    created() {
      this.makeRequest();
    },

  }
</script>

<style scoped lang="sass">

</style>